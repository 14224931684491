import React, { Component } from "react";
import MainHeader from "./common/mainHeader";
import Footer from "./common/footer";
import axios from "axios";

import config from "../config.json";
const apiEndPoint = config.apiUrl + "/v1/news";

class ShowNews extends Component {
  state = {
    info: {},
    loading: false,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const { data: info } = await axios.get(
      apiEndPoint + "/" + this.props.match.params.id
    );
    this.setState({ info, loading: false });
  }

  render() {
    const { info, loading } = this.state;

    if (loading) {
      return <div className="loader"></div>;
    } else {
      return (
        <React.Fragment>
          <MainHeader />

          <div className="container block-secondary-page">
            <img
              className="block-secondary-page__image"
              src={config.imagesBaseUrl + "/" + info.image}
              alt={info.title}
            />
          </div>

          <section className="block container block-news">
            <header className="block__header">
              <h2>{info.title}</h2>
              <span className="block-news__date">{info.date}</span>
              <p>{info.description}</p>
            </header>
          </section>

          <Footer footer="secondary" />
        </React.Fragment>
      );
    }
  }
}

export default ShowNews;
