import React, { Component } from "react";

class NDASection extends Component {
  render() {
    const { info } = this.props;
    return (
      <section className="block block-nda">
        <div className="container">
          <header className="block__header">
            <h2>USDA NONDISCRIMATION STATEMENT</h2>
          </header>
          <div className="block-nda__detail">
            <p>
              In accordance with Federal civil rights law and U.S. Department of
              Agriculture (USDA) civil rights regulations and policies, the
              USDA, its Agencies, offices, and employees, and institutions
              participating in or administering USDA programs are prohibited
              from discriminating based on race, color, national origin, sex,
              disability, age, or reprisal or retaliation for prior civil rights
              activity in any program or activity conducted or funded by USDA.
            </p>
            <p>
              Persons with disabilities who require alternative means of
              communication for program information (e.g. Braille, large print,
              audiotape, American Sign Language, etc.), should contact the
              Agency (State or local) where they applied for benefits.
              Individuals who are deaf, hard of hearing or have speech
              disabilities may contact USDA through the Federal Relay Service at
              (800) 877-8339. Additionally, program information may be made
              available in languages other than English.
            </p>
            <p>
              To file a program complaint of discrimination, complete the USDA
              Program Discrimination Complaint Form, (AD-3027) found online at:
              www.ascr.usda.gov/complaint_filing_cust.html, and at any USDA
              office, or write a letter addressed to USDA and provide in the
              letter all of the information requested in the form. To request a
              copy of the complaint form, call (866) 632-9992. Submit your
              completed form or letter to USDA by:
            </p>
            <p className="block-nda__address">
              <span>Mail: U.S. Department of Agriculture</span>
              <span>Office of the Assistant Secretary for Civil Rights</span>
              <span>1400 Independence Avenue, SW</span>
              <span>Washington, D.C. 20250-9410;</span>
              <span>Fax: (202) 690-7442; or</span>
              <span>Email: program.intake@usda.gov.</span>
              <span>This institution is an equal opportunity provider.</span>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default NDASection;
