import React, { Component } from "react";
import MainHeader from "./common/mainHeader";
import SecondaryPageBanner from "./common/secondaryPageBanner";
import Footer from "./common/footer";
import AOS from "aos";

class About extends Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <React.Fragment>
        <MainHeader />
        <SecondaryPageBanner
          title="About Us Page Top Banner"
          imageName="about"
        />

        <section className="block container block-about">
          <header className="block__header">
            <h2>Nutri Meal Resources</h2>
            <p>
              Nutri Meal Resources (NMR) is a sponsor of the Child and Adult
              Care Food Program (CACFP) which is funded by the United States
              Department of Agriculture (USDA) and administered through the
              Texas Department of Agriculture. Our program is committed to
              raising the bar and setting a standard in Child and Adult Care
              Food Program Sponsorship. It is our goal to inform the community
              about making healthy food choices and to educate adults and
              children about the importance of proper nutrition. This
              information is another step in fighting the epidemic of childhood
              obesity. NMR sponsors childcare providers, adult daycare centers
              and at-risk centers.
            </p>
          </header>
        </section>

        <section className="block block--grey block-different">
          <div className="container">
            <header className="block__header">
              <h2>Why Choose Us?</h2>
              <p></p>
            </header>
            <div className="grid grid--1x4" data-aos="zoom-in">
              <div className="icon-group">
                <span className="icon-container">
                  <svg className="icon">
                    <use href="images/block-different-sprite.svg#education"></use>
                  </svg>
                </span>
                <h3>Nutrition Education</h3>
              </div>
              <div className="icon-group">
                <span className="icon-container">
                  <svg className="icon">
                    <use href="images/block-different-sprite.svg#resources"></use>
                  </svg>
                </span>
                <h3>Resource</h3>
              </div>
              <div className="icon-group">
                <span className="icon-container">
                  <svg className="icon">
                    <use href="images/block-different-sprite.svg#money"></use>
                  </svg>
                </span>
                <h3>Reimbursement</h3>
              </div>
              <div className="icon-group">
                <span className="icon-container">
                  <svg className="icon">
                    <use href="images/block-different-sprite.svg#support"></use>
                  </svg>
                </span>
                <h3>Support</h3>
              </div>
            </div>
          </div>
        </section>

        <div className="block container">
          <header className="block__header"></header>
          <article className="grid grid--1x2 feature">
            <div className="feature__content" data-aos="fade-right">
              <h3 className="feature__heading">Mission</h3>
              <p>
                Our mission is to educate and empower our community to make
                healthy food choices for generations to come. Our goal is to
                encourage and support well-being and success of children, adults
                and child care community.
              </p>
            </div>
            <picture data-aos="fade-left">
              <source
                type="image/webp"
                srcset="images/mission.webp 1x, images/mission@2x.webp 2x"
              />
              <source
                type="image/jpg"
                srcset="images/mission.jpg 1x, images/mission@2x.jpg 2x"
              />
              <img
                className="feature__image"
                src="images/mission@2x.jpg"
                alt=""
              />
            </picture>
          </article>
          <article className="grid grid--1x2 feature">
            <div className="feature__content" data-aos="fade-left">
              <h3 className="feature__heading">About CACFP</h3>
              <p>
                The Child And Adult Care Food Program (CACFP) is a nutrition
                assistance program that provides supplemental reimbursement for
                eligible meals and snacks that are served to program eligible
                participants. The goal of the CACFP is to improve and maintain
                the health and nutritional status of children and adults,
                promote development of good eating habits, and integrate
                nutritious food service with organized child and adult day care
                services.
              </p>
            </div>

            <img
              data-aos="fade-right"
              className="feature__image"
              src="images/aboutus.png"
              alt=""
            />
          </article>
        </div>
        <Footer footer="secondary" />
      </React.Fragment>
    );
  }
}

export default About;
