import React, { Component } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

class TestimonialSection extends Component {
  render() {
    const { info } = this.props;
    return (
      <section className="block">
        <header className="block__header">
          <h2>Providers love NMR Nutrition Program</h2>
          <p>
            {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut,
            ipsam. */}
          </p>
        </header>
        <div className="container">
          <div className="card testimonial ">
            <Splide>
              <SplideSlide>
                <div className="grid grid--1x2 ">
                  <div className="testimonial__image">
                    <img
                      src="images/testimonial.jpg"
                      alt="A happy, smiling customer"
                    />
                    <span className="icon-container icon-container--accent">
                      <svg className="icon icon--white icon--small">
                        <use href="images/sprite.svg#quotes"></use>
                      </svg>
                    </span>
                  </div>
                  <blockquote className="quote">
                    <p className="quote__text">
                      I would like to mention that Nutri Meal staff is well
                      organized and helpful. From the time since enrollment, I
                      feel they took great care at every step. The staff is very
                      knowledgeable about the process and what to do. The
                      attitude and patience was very noticeable as well. I feel
                      very comfortable coming in for assistance or calling for
                      help. Thank you so much for your professionalism.
                    </p>
                    <footer>
                      <div className="media">
                        <div className="media__image">
                          <svg className="icon icon--primary quote__line">
                            <use href="images/sprite.svg#line"></use>
                          </svg>
                        </div>
                        <div className="media__body">
                          <h3 className="media__title quote__author">
                            Sarah M
                          </h3>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="grid grid--1x2">
                  <div className="testimonial__image">
                    <img
                      src="images/testimonial.jpg"
                      alt="A happy, smiling customer"
                    />
                    <span className="icon-container icon-container--accent">
                      <svg className="icon icon--white icon--small">
                        <use href="images/sprite.svg#quotes"></use>
                      </svg>
                    </span>
                  </div>
                  <blockquote className="quote">
                    <p className="quote__text">
                      I love the Nutri Meal food program! NMR has always been
                      there for me. It has help me with resources to manage my
                      center much better and also with lots of healthy menu
                      options available in the software.
                    </p>
                    <footer>
                      <div className="media">
                        <div className="media__image">
                          <svg className="icon icon--primary quote__line">
                            <use href="images/sprite.svg#line"></use>
                          </svg>
                        </div>
                        <div className="media__body">
                          <h3 className="media__title quote__author">
                            Roberta Davis
                          </h3>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </section>
    );
  }
}

export default TestimonialSection;
