import React, { Component } from "react";
import MainHeader from "./common/mainHeader";
import SecondaryPageBanner from "./common/secondaryPageBanner";
import Footer from "./common/footer";
import axios from "axios";
import config from "../config.json";
const apiEndPoint = config.apiUrl + "/v1/resources";

class Resources extends Component {
  state = {
    loading: false,
    forms: [],
    posters: [],
    meal_pattrens: [],
    tools: [],
    miscellaneous: [],
  };

  async componentDidMount() {
    this.getData(this.state.currentPage);
  }

  getData = async (page) => {
    this.setState({ loading: true });
    const { data: resources } = await axios.get(apiEndPoint);
    this.setState({
      forms: resources.forms,
      posters: resources.posters,
      meal_pattrens: resources.meal_pattrens,
      tools: resources.tools,
      miscellaneous: resources.miscellaneous,
      loading: false,
    });
  };

  render() {
    const { loading, forms, posters, meal_pattrens, tools, miscellaneous } =
      this.state;

    if (loading) {
      return <div className="loader"></div>;
    } else {
      return (
        <React.Fragment>
          <MainHeader />
          <SecondaryPageBanner
            title="Resources Page Top Banner"
            imageName="resource"
          />
          <section className="block container block-about block-files">
            <header className="block__header">
              <h2>Forms</h2>
            </header>
            {forms.map((item) => (
              <article className="block-files__download">
                <h3>{item.name}</h3>
                <a
                  href={`${config.imagesBaseUrl}/${item.image}`}
                  target="_blank"
                  className="btn btn--small btn--primary"
                >
                  Download
                </a>
              </article>
            ))}
          </section>

          <section className="block block--skewed-left block--grey block-about">
            <div className="container block-files">
              <header className="block__header">
                <h2>Posters</h2>
              </header>
              {posters.map((item) => (
                <article className="block-files__download">
                  <h3>{item["name"]}</h3>
                  <a
                    href={`${config.imagesBaseUrl}/${item.image}`}
                    target="_blank"
                    className="btn btn--small btn--primary"
                  >
                    Download
                  </a>
                </article>
              ))}
            </div>
          </section>
          <section className="block container block-about block-files">
            <header className="block__header">
              <h2>MEAL PATTERNS</h2>
            </header>
            {meal_pattrens.map((item) => (
              <article className="block-files__download">
                <h3>{item["name"]}</h3>
                <a
                  href={`${config.imagesBaseUrl}/${item.image}`}
                  target="_blank"
                  className="btn btn--small btn--primary"
                >
                  Download
                </a>
              </article>
            ))}
          </section>
          <section className="block block--grey block-about">
            <div className="container block-files">
              <header className="block__header">
                <h2>Tools</h2>
              </header>
              {tools.map((item) => (
                <article
                  className="block-files__download"
                  style={{ borderBottomColor: "#efdede" }}
                >
                  <h3>{item["name"]}</h3>
                  <a
                    href={
                      item.url != ""
                        ? `${item.url}`
                        : `${config.imagesBaseUrl}/${item.image}`
                    }
                    target="_blank"
                    className="btn btn--small btn--primary"
                  >
                    Download
                  </a>
                </article>
              ))}
            </div>
          </section>
          {/* <section className="block container block-about block-files">
            <header className="block__header">
              <h2 className="block-files__heading">MISCELLANEOUS</h2>
            </header>
            {miscellaneous.map((item) => (
              <article className="block-files__download">
                <h3>{item["name"]}</h3>
                <a
                  href={`${config.imagesBaseUrl}/${item.image}`}
                  target="_blank"
                  className="btn btn--small btn--accent"
                >
                  Download
                </a>
              </article>
            ))}
          </section> */}

          <Footer footer="secondary" />
        </React.Fragment>
      );
    }
  }
}

export default Resources;
