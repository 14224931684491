import React, { Component } from "react";
import { Link } from "react-router-dom";

class CalloutSection extends Component {
  render() {
    const { info } = this.props;
    return (
      <div className="callout container callout-signup callout--primary">
        <div className="grid grid--1x2">
          <div className="callout__content">
            <h2 className="callout__heading">Our Program</h2>
            <p>
              Our program is staffed to provide assistance through every step of
              the program
            </p>
          </div>

          <Link className="btn btn--accent" to={"/contact-us"}>
            Enroll Today
          </Link>
        </div>
      </div>
    );
  }
}

export default CalloutSection;
