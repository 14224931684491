import React, { Component } from "react";
import MainHeader from "./common/mainHeader";
import SecondaryPageBanner from "./common/secondaryPageBanner";
import Footer from "./common/footer";
import AOS from "aos";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";

class Programs extends Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <React.Fragment>
        <MainHeader />
        <SecondaryPageBanner
          title="Services Page Top Banner"
          imageName="programs"
        />

        <section className="block container block-about">
          <header className="block__header">
            <h2>We Know You Want The Best For Your Children</h2>
            <p>
              Child care is no easy job! Licensing regulations needs child care
              centers to follow nutritional structure. Thus, why not receive a
              little money to help you be your best? We believe our mentorship
              can guide you to simple, straight forward ways to serve children
              healthy foods and get reimbursed! NMR Nutrition Program supports
              the child care community in serving healthy meals to children in
              partnership with the Child and Adult Care Food Program (CACFP).
            </p>
          </header>
        </section>

        <section className="block block--grey block-different">
          <div className="container">
            <header className="block__header">
              <h2>We Do The Hard Work For You</h2>
              <p>
                NMR Food Program gives you training, menu recording software,
                recipes & support your food program. With simple record-keeping
                practices and a little bit of training, you can start getting
                paid to have your children eat healthy.
              </p>
            </header>
            <div className="grid grid--1x3" data-aos="zoom-in">
              <div className="icon-group">
                <span className="icon-container">
                  <img src="images/healthy-menu.svg" width="70" alt="" />
                </span>
                <h3>Healthy Menus</h3>
                <p>
                  Learn how to plan quick and easy menus that are healthy,
                  delicious and cost-effective.
                </p>
              </div>
              <div className="icon-group">
                <span className="icon-container">
                  <svg className="icon">
                    <use href="images/block-different-sprite.svg#resources"></use>
                  </svg>
                </span>
                <h3>Personalized Support</h3>
                <p>
                  Receive updates and regular training on child development and
                  nutrition.
                </p>
              </div>
              <div className="icon-group">
                <span className="icon-container">
                  <svg className="icon">
                    <use href="images/block-different-sprite.svg#money"></use>
                  </svg>
                </span>
                <h3>Good Nutrition Pays</h3>
                <p>
                  Get paid for the nutritious food you serve to children in your
                  care.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="block block-steps">
          <div className="container">
            <header class="block__header">
              <h2>Here's How It Works</h2>
            </header>
            <div class="block-steps__detail">
              <Splide
                options={{
                  pagination: false,
                  speed: 2000,
                  perPage: 3,
                  perMove: 1,
                }}
              >
                <SplideSlide>
                  <div class="card card--primary block-steps__step">
                    <header class="card__header">Step 1</header>
                    <div class="card__body block-steps__step_detail">
                      <img className="icon" src="images/enroll.svg" alt="" />
                      <h3 className="block-steps__heading">Enroll Daycare</h3>
                      <p className="block-steps__description">
                        Call us to start enrollment process
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div class="card card--primary block-steps__step">
                    <header class="card__header">Step 2</header>
                    <div class="card__body block-steps__step_detail">
                      <img src="images/training.svg" alt="" className="icon" />
                      <h3 class="block-steps__heading">Training</h3>
                      <p class="block-steps__description">
                        Receive training at your facility on serving healthy
                        meals
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="card card--primary block-steps__step">
                    <header className="card__header">Step 3</header>
                    <div className="card__body block-steps__step_detail">
                      <img
                        src="images/participant.svg"
                        alt=""
                        className="icon"
                      />
                      <h3 className="block-steps__heading">
                        Enroll Participant
                      </h3>
                      <p className="block-steps__description">
                        Fill out paperwork and submit to NMR to enroll the
                        participant in your center
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="card card--primary block-steps__step">
                    <header className="card__header">Step 4</header>
                    <div className="card__body block-steps__step_detail">
                      <img src="images/food.svg" alt="" className="icon" />
                      <h3 className="block-steps__heading">
                        Serve Nutritious Food
                      </h3>
                      <p className="block-steps__description">
                        Follow guidelines to prepare and serve healthy food
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="card card--primary block-steps__step">
                    <header className="card__header">Step 5</header>
                    <div className="card__body block-steps__step_detail">
                      <img src="images/claims.svg" alt="" className="icon" />
                      <h3 className="block-steps__heading">Submit Claim</h3>
                      <p className="block-steps__description">
                        Record all meals served to each participant and submit
                        record to NMR
                      </p>
                    </div>
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="card card--primary block-steps__step">
                    <header className="card__header">Step 6</header>
                    <div className="card__body block-steps__step_detail">
                      <img src="images/payments.svg" alt="" className="icon" />
                      <h3 className="block-steps__heading">Receive Payment</h3>
                      <p class="block-steps__description">
                        NMR delivers reimbursement either in your bank account
                        (Direct Deposit) or check
                      </p>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </section>

        <section class="block block--grey block-nda block-services">
          <header class="block__header">
            <h2 style={{ opacity: 1 }}>
              You Focus on your Child Care Center
              <br /> We Focus on making your Nutrition Program Easy!
              <br /> Start Serving Nutritious Meals Today!
            </h2>
          </header>
          <div class="block-nda__detail">
            <center>
              <p
                style={{
                  fontSize: "2.4rem",
                  textAlign: "center",
                  color: "#fff",
                  background: "#d98347",
                  opacity: 0.8,
                  padding: 0,
                  width: "1024px",
                }}
              >
                It's never been easier to save money on healthy food. If you are
                a licensed child care
                <br /> provider, this program is for you. There is no cost to
                participate.
                <br /> Call our Office at 832.339.6677 to join our Food Program.
              </p>
            </center>
          </div>

          <div class="block__controls">
            <Link className="btn btn--accent" to={"/contact-us"}>
              sign me up
            </Link>
          </div>
        </section>

        <Footer footer="secondary" />
      </React.Fragment>
    );
  }
}

export default Programs;
