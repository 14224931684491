import React, { Component } from "react";
import { Link } from "react-router-dom";

class AboutSection extends Component {
  render() {
    const { info } = this.props;
    return (
      <section className="block container block-about">
        <header className="block__header">
          <h2>About Us</h2>
          <p>
            Nutri Meal Resources is a sponsor of the Child and Adult Care Food
            Program (CACFP) which is funded by the United States Department of
            Agriculture (USDA) and administered through the Texas Department of
            Agriculture. Our program is committed to raising the bar and setting
            a standard in Child and Adult Care Food Program Sponsorship.
          </p>
          
          <Link className="btn btn--primary"
            to={"/about"}        
          >
            read more
          </Link>
        </header>
      </section>
    );
  }
}

export default AboutSection;
