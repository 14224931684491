import React, { Component } from "react";
import MainHeader from "./common/mainHeader";
import SecondaryPageBanner from "./common/secondaryPageBanner";
import Footer from "./common/footer";
import Input from "./common/input";
import axios from "axios";
import config from "../config.json";

const apiEndPoint = config.apiUrl + "/v1/save-contact";

class ContactUs extends Component {
  state = {
    data: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    info: {},
    errors: {},
    loading: false,
    card: true,
    message: "none",
  };

  validateProperty = ({ name, value }) => {
    if (name == "name") {
      if (value.trim() === "") return "Name is required.";
    }

    if (name == "email") {
      if (value.trim() === "") return "Email is required.";
    }

    if (name == "subject") {
      if (value.trim() === "") return "Subject is required.";
    }
  };

  handleChange = ({ currentTarget: input }) => {
    let errors = { ...this.state.errors };

    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  validate = () => {
    const errors = {};
    const { data } = this.state;

    if (data.name.trim() === "") errors.name = "Name is required.";

    if (data.email.trim() === "") errors.email = "Email is required.";

    if (data.subject.trim() === "") errors.subject = "Subject is required.";

    if (data.message.trim() === "") errors.message = "Message is required.";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();

    this.setState({ errors: errors || {} });

    if (errors) return;

    this.setState({ loading: true });

    //call the server and redirect the user to confirmation page.
    const { data: contact } = await axios.post(apiEndPoint, this.state.data);

    this.setState({
      data: { name: "", email: "", subject: "", message: "" },
      loading: false,
      card: "none",
      message: "block",
    });
    document.getElementById("message").value = "";
  };

  render() {
    const { data, errors, info, card, message, loading } = this.state;

    if (loading) {
      return <div className="loader"></div>;
    } else {
      return (
        <React.Fragment>
          <MainHeader />
          <SecondaryPageBanner
            title="Contact Us Page Top Banner"
            imageName="contact"
          />

          <section className="block container block-contact">
            <header className="block__header">
              <h2>We'd Love to Hear From You, Get In Touch With Us!</h2>
              <p>
                Please fill out the form and submit your questions or comments.
              </p>
              <div>
                <h3>Our Office</h3>
                22503 Katy Fwy, Katy, TX 77450
                <br />
                Email: contact@nutrimeal.org
                <br />
                Phone: +1 832 339 6677
              </div>
            </header>

            <form onSubmit={this.handleSubmit} method="post">
              <div
                className="grid grid--1x2 card"
                style={{ display: `${message}` }}
              >
                Your query has been submitted successfully.
              </div>
              <div
                className="grid grid--1x2 card"
                style={{ display: `${card}` }}
              >
                {/* <label>Name</label>
            <input className="input" type="text" />
            <label>Email</label>
            <input className="input" type="text" />
            <label>Subject</label>
            <input className="input" type="text" />
            <label>Detail</label>
            <textarea className="input"></textarea>
            <button className="btn btn--primary">Submit</button> */}
                <label>Name</label>
                <Input
                  name="name"
                  error={errors.name}
                  label=""
                  value={data.name}
                  onChange={this.handleChange}
                />

                <label>Email</label>
                <Input
                  name="email"
                  error={errors.email}
                  label=""
                  value={data.email}
                  onChange={this.handleChange}
                />

                <label>Subject</label>
                <Input
                  name="subject"
                  error={errors.subject}
                  label=""
                  value={data.subject}
                  onChange={this.handleChange}
                />

                <label>Message</label>

                <textarea
                  placeholder="Your Message"
                  onChange={this.handleChange}
                  name="message"
                  id="message"
                  rows="8"
                  tabindex="4"
                >
                  {data.message}
                </textarea>

                <button className="btn btn--primary">Send</button>
              </div>
            </form>
          </section>

          <Footer footer="secondary" />
        </React.Fragment>
      );
    }
  }
}

export default ContactUs;
