import React, { Component } from "react";

class SecondaryPageBanner extends Component {
  render() {
    const { title, imageName } = this.props;

    return (
      <section className="block block-secondary-page about">
        <div className="container grid grid--1x1 block-secondary-page">
          <img
            className="block-secondary-page__image"
            src={`images/${imageName}.jpg`}
            alt={title}
          />
        </div>
      </section>
    );
  }
}

export default SecondaryPageBanner;
