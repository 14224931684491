import React, { Component } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

class HeroSection extends Component {
  render() {
    return (
      <Splide
        options={{
          type: "fade",
          easing: "cubic-bezier(0.25, 1, 0.5, 1)",
          pagination: false,
          speed: 2000,
          arrows: false,
          autoplay: true,
          rewind: true,
          interval: 4000,
        }}
        className="block-slider-main"
      >
        <SplideSlide>
          <section
            className="block hero block-slider-image"
            style={{
              background: 'url("../../../images/home_slider_1.jpg")',
            }}
          >
            <div className="container grid grid--1x2 block-slider-image__first">
              <div>&nbsp;</div>
              <header
                className="block__header hero__content"
                style={{ textAlign: "left" }}
              >
                <h1 className="block__heading">
                  Todays Children are tomorrow's Future.
                </h1>
                <p className="hero__tagline" style={{ fontSize: "2.5rem" }}>
                  Creating a healthy & sustainable future for the children of
                  Texas.
                </p>
              </header>
            </div>
          </section>
        </SplideSlide>
        <SplideSlide>
          <section
            className="block hero block-slider-image"
            style={{
              background: 'url("../../../images/home_slider_2.jpg")',
            }}
          >
            <div className="container grid grid--1x1">
              <div></div>
              <header
                className="block__header hero__content"
                style={{ marginTop: "10rem" }}
              >
                <h1
                  className="block__heading"
                  style={{ color: "#fff", fontSize: "5rem" }}
                >
                  Making a difference in lives
                  <br /> of young Children!
                </h1>
                <p
                  className="hero__tagline"
                  style={{ color: "#fff", fontSize: "2.5rem" }}
                >
                  Giving your child a healthy start.
                </p>
              </header>
            </div>
          </section>
        </SplideSlide>
        <SplideSlide>
          <section
            className="block hero block-slider-image"
            style={{
              background: 'url("../../../images/home_slider_3.jpg")',
            }}
          >
            <div className="container grid grid--1x1">
              <div></div>
              <header
                className="block__header hero__content"
                style={{ marginTop: "10rem" }}
              >
                <h1
                  className="block__heading"
                  style={{ color: "#fff", fontSize: "5rem" }}
                >
                  A Healthy Start for Life!
                </h1>
              </header>
            </div>
          </section>
        </SplideSlide>
      </Splide>
    );
  }
}

export default HeroSection;
