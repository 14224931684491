import React, { Component } from "react";

class SupportBusinessSection extends Component {
  render() {
    const { info } = this.props;
    return (
      <section className="block block--grey block--skewed-left block-support-business">
        <div className="container">
          <header className="block__header">
            <h2>We Partner with</h2>
            <p>
              From Nutrition to Child care reimbursement, we help support your
              business and the children in your care.
            </p>
          </header>
          <div className="grid grid--1x3" data-aos="zoom-in">
            <img src="images/nca.png" alt="National CACFP Association" />
            <img src="images/doac.png" alt="Department of agriculture texas" />
            {/* <img src="images/cafp.png" alt="CACFP" /> */}
            <img src="images/usda.png" width="190" alt="USDA" />
          </div>
        </div>
      </section>
    );
  }
}

export default SupportBusinessSection;
