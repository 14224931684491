import React, { Component } from "react";
import MainHeader from "./common/mainHeader";
import HeroSection from "./home/heroSection";
import AboutSection from "./home/aboutSection";
import DifferentSection from "./home/differentSection";
import NewsSection from "./home/newsSection";
import TestimonialSection from "./home/testimonialSection";
import SupportBusinessSection from "./home/supportBusinessSection";
import NDASection from "./home/ndaSection";
import CalloutSection from "./home/calloutSection";
import Footer from "./common/footer";

import AOS from "aos";

class Home extends Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <React.Fragment>
        <MainHeader />
        <HeroSection />
        <AboutSection />
        <DifferentSection />
        <NewsSection />
        <TestimonialSection />
        <SupportBusinessSection />
        <NDASection />
        <CalloutSection />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
