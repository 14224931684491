import React, { Component } from "react";
import { Link } from "react-router-dom";

class DifferentSection extends Component {
  render() {
    const { info } = this.props;
    return (
      <section className="block block--grey block-different">
        <div className="container">
          <header className="block__header">
            <h2>What Makes us different?</h2>
            <p></p>
          </header>
          <div className="grid grid--1x4" data-aos="zoom-in">
            <div className="icon-group">
              <span className="icon-container">
                <svg className="icon">
                  <use href="images/block-different-sprite.svg#education"></use>
                </svg>
              </span>
              <h3>Nutrition Education</h3>
            </div>
            <div className="icon-group">
              <span className="icon-container">
                <svg className="icon">
                  <use href="images/block-different-sprite.svg#resources"></use>
                </svg>
              </span>
              <h3>Resource</h3>
            </div>
            <div className="icon-group">
              <span className="icon-container">
                <svg className="icon">
                  <use href="images/block-different-sprite.svg#money"></use>
                </svg>
              </span>
              <h3>Reimbursement</h3>
            </div>
            <div className="icon-group">
              <span className="icon-container">
                <svg className="icon">
                  <use href="images/block-different-sprite.svg#support"></use>
                </svg>
              </span>
              <h3>Support</h3>
            </div>
          </div>
          <div className="block__controls">
            <Link className="btn btn--primary" to={"/about"}>
              read more
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default DifferentSection;
