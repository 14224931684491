import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Home from "./components/home";
import About from "./components/about";
import Programs from "./components/programs";
import Resources from "./components/resources";
import ContactUs from "./components/contactus";
import ShowNews from "./components/showNews";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/news/:id" component={ShowNews} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/resources" component={Resources} />
          <Route path="/programs" component={Programs} />
          <Route path="/about" component={About} />
          <Route path="/" exact component={Home} />

          <Redirect to="/page-not-found" />
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
