import React, { Component } from "react";

class Footer extends Component {
  state = {
    footer: "primary",
  };

  componentDidMount() {
    if (this.props.footer != null) this.setState({ footer: this.props.footer });
  }

  render() {
    const classses =
      this.state.footer == "primary"
        ? "footer footer-small--main block-footer block-dark"
        : "footer footer-small--other block-footer block-dark";
    return (
      <footer className={classses}>
        <p>&copy; 2023 Nutri Meal Resources. All Rights Reserved</p>
      </footer>
    );
  }
}

export default Footer;
