import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import config from "../../config.json";
const apiEndPoint = config.apiUrl + "/v1/news";

class NewsSection extends Component {
  state = {
    news: [],
    loading: false,
  };

  async componentDidMount() {
    this.getData(this.state.currentPage);
  }

  getData = async (page) => {
    this.setState({ loading: true });
    const { data: news } = await axios.get(apiEndPoint);
    this.setState({
      news: news,
      loading: false,
    });
  };

  render() {
    const { loading, info, news, totalRecords, pageSize, currentPage } =
      this.state;

    if (loading) {
      return <div className="loader"></div>;
    } else {
      return (
        <section className="block block--dark block--skewed-right block-showcase">
          <header className="block__header">
            <h2>News and Updates</h2>
          </header>
          <div className="container grid grid--1x2">
            <img
              className="block-showcase__image"
              data-aos="fade-right"
              src="images/news.png"
              alt="News and Updates section image"
            />

            <ul className="list" data-aos="fade-left">
              {news.map((item) => (
                <li>
                  <div className="media">
                    <div className="media__image">
                      <img
                        src={`${config.imagesBaseUrl}/${item.thumb}`}
                        alt={item.title}
                      />
                    </div>
                    <div className="media__body">
                      <h3 className="media__heading">
                        <Link to={"/news/" + item.id}>{item.title}</Link>
                      </h3>
                      <p>{item.short}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
      );
    }
  }
}

export default NewsSection;
