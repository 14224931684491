import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navigation extends Component {
  render() {
    return (
      <nav className="nav nav--white collapsible">
        <Link className="nav__brand" to="/">
          <img src="/images/logo.png" alt="Nutrimeal logo" />
        </Link>
        <svg class="icon icon--black nav__toggler">
          <use href="images/sprite.svg#menu"></use>
        </svg>
        <ul className="list nav__list collapsible__content">
          <li className="nav__item">
            <Link to="/">Home</Link>
          </li>
          <li className="nav__item">
            <Link to="/about">About</Link>
          </li>
          <li className="nav__item">
            <Link to="/programs">Services</Link>
          </li>
          <li className="nav__item">
            <Link to="/resources">Resources</Link>
          </li>
          <li className="nav__item">
            <Link to="/contact-us">Contact</Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
